<template>
  <section>
    <div class="about-container">
      <div class="sidebar hidden lg:block">
        <p class="side-title">{{ $t("aboutLink") }}</p>
        <div class="w-full flex flex-column justify-content-start align-content-center gap-3 mt-2">
          <NuxtLink :class="isLinkactive('story') ? 'active' : ''" to="/about/story"
            >Historien om Peppes Pizza
          </NuxtLink>
          <NuxtLink
            to="/about/vacant-positions"
            :class="isLinkactive('vacant-positions') ? 'active' : ''"
            >Ledige stillinger
          </NuxtLink>
          <NuxtLink
            to="/about/company-at-peppes"
            :class="isLinkactive('company-at-peppes') ? 'active' : ''"
          >
            Selskap hos Peppes
          </NuxtLink>
          <NuxtLink
            to="/about/student-discount"
            :class="isLinkactive('student-discount') ? 'active' : ''"
            >Studentrabatt
          </NuxtLink>
          <NuxtLink
            to="/about/nutritional-content"
            :class="isLinkactive('nutritional-content') ? 'active' : ''"
          >
            Næringsinnhold
          </NuxtLink>
          <NuxtLink to="/about/restaurants" :class="isLinkactive('restaurants') ? 'active' : ''">
            Restauranter
          </NuxtLink>
          <NuxtLink
            :class="isLinkactive('privacy-statement') ? 'active' : ''"
            to="/about/privacy-statement"
          >
            Personvernerklæring
          </NuxtLink>
          <NuxtLink :class="isLinkactive('business-user') ? 'active' : ''" to="/about/business-user"
            >Bedriftskunder
          </NuxtLink>
          <NuxtLink :class="isLinkactive('feedback') ? 'active' : ''" to="/about/feedback"
            >Ris & Ros
          </NuxtLink>
          <NuxtLink
            :class="isLinkactive('giftCard') ? 'active' : ''"
            to="https://engine.gogift.com/peppesno/product/802114241831034880"
            >Gavekort
          </NuxtLink>
        </div>
      </div>
      <div class="main-content">
        <slot />
      </div>
    </div>
    <div class="footer about-page">
      <div
        class="w-full"
        :style="{
          backgroundImage: 'url(' + `${landingBorder}` + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '1.75rem',
        }"
      ></div>
      <div class="container h-full">
        <div
          class="w-full flex justify-content-center align-items-center h-full flex-column gap-3 md:flex-row md:justify-content-between"
        >
          <div class="phone-box">
            <i class="pi pi-phone" />
            <p>2222 5555</p>
          </div>
          <p class="footer-middle-text">
            <a
              href="https://www.peppes.no/webshop/personvern?1&from=fp"
              style="color: inherit; text-decoration: none"
              >Personvernserklæring</a
            >
          </p>
          <div class="copy-right">
            <p>@ Peppes Pizza 2025</p>
            <p>Orgnr: 984 388 659</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import landingBorder from "assets/logo/landing-border.svg";

const route = useRoute();
const isLinkactive = computed(() => {
  return (link: string) => {
    switch (link) {
      case "story":
        return route.path === "/about/story";
      case "vacant-positions":
        return route.path === "/about/vacant-positions";
      case "company-at-peppes":
        return route.path === "/about/company-at-peppes";
      case "student-discount":
        return route.path === "/about/student-discount";
      case "nutritional-content":
        return route.path === "/about/nutritional-content";
      case "restaurants":
        return route.path === "/about/restaurants";
      case "privacy-statement":
        return route.path === "/about/privacy-statement";
      case "business-user":
        return route.path === "/about/business-user";
      case "feedback":
        return route.path === "/about/feedback";
      default:
        return false;
    }
  };
});
</script>

<style scoped></style>
